.footer-footer4 {
  gap: 80px;
  width: 100%;
  height: 39px;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-credits {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.footer-row {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
.footer-footer-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
}
@media(max-width: 767px) {
  .footer-row {
    flex-direction: column;
  }
  .footer-footer-links {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .footer-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
