.bio-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.bio-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.bio-image-container {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
}
.bio-image {
  width: 650px;
  height: 640px;
}

.bio-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.bio-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.bio-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
@media(max-width: 991px) {
  .bio-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .bio-image {
    max-width: 650px;
    max-height: 640px;
    height: 100%;
    width: 80%;
    margin: 0 auto;
  }
  
}

@media (max-width: 991px) {
  .bio-layout349 {
    padding: 20px; /* Adds padding around the entire bio section */
  }

  .bio-max-width {
    flex-direction: column; /* Stacks the image and content vertically */
    width: 100%; /* Uses the full width */
  }

  .bio-image-container {
    width: 100%; /* Container takes full width */
    justify-content: center; /* Centers the image horizontally */
  }

  .bio-image {
    width: 100%; /* Image takes full width of its container */
    height: auto; /* Keeps the aspect ratio of the image */
    max-width: 650px; /* Limits the size on larger mobile devices */
    max-height: none; /* Removes max-height restriction */
  }

  .bio-content {
    width: 100%; /* Content takes full width */
  }

  .bio-section-title,
  .bio-content1 {
    width: 100%; /* Ensures that content sections use full width */
  }
}

* {
  box-sizing: border-box; /* Ensures padding and borders are included in the width */
  max-width: 100%; /* Prevents any element from exceeding the body width */
}