.contact-form10-contact11 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}
.contact-form10-max-width {
  display: flex;
  flex-direction: column;
}
.contact-form10-section-title {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.contact-form10-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-content1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.contact-form10-form {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-form10-input {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-input1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-text-input1 {
  background-color: var(--dl-color-theme-neutral-light);
}
.contact-form10-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-input2 {
  gap: var(--dl-space-space-halfunit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-input3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-checkbox {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}
.contact-form10-content2 {
  align-items: stretch;
}
.contact-form10-row {
  align-items: flex-start;
}
.contact-form10-icon {
  width: 32px;
  height: 32px;
}
.contact-form10-contact-info {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-content4 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.contact-form10-icon2 {
  width: 32px;
  height: 32px;
}
.contact-form10-contact-info1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form10-iframe {
  width: 100%;
  height: auto;
  min-height: 450px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .contact-form10-form {
    width: 50%;
  }
  .contact-form10-container {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form10-input2 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    flex-direction: column;
  }
  .contact-form10-content2 {
    width: 50%;
  }
  .contact-form10-row {
    flex-direction: column;
  }
  .contact-form10-content3 {
    width: 50%;
  }
  .contact-form10-contact-info {
    width: 50%;
  }
  .contact-form10-content4 {
    width: 50%;
  }
  .contact-form10-row1 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .contact-form10-input2 {
    width: 100%;
  }
  .contact-form10-row1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .contact-form10-content1 {
    flex-direction: column;
  }
  .contact-form10-form {
    width: 100%;
  }
  .contact-form10-input2 {
    width: 100%;
  }
  .contact-form10-content2 {
    width: 100%;
  }
  .contact-form10-row {
    flex-direction: column;
  }
  .contact-form10-content3 {
    width: 100%;
  }
  .contact-form10-content4 {
    width: 100%;
  }
  .contact-form10-row1 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
}
