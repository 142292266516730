.project1-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-fiveunits);
  position: relative;
  grid-template-columns: 1fr 1fr;
}

.project1-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project1-image {
  max-width: 100%;
  max-height: 500px; /* Increased the fixed height for the image container */
  object-fit: contain; /* Keeps the image's aspect ratio */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  border-radius: 8px; /* Adds rounded edges */
}

.project1-tabs-menu {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.project1-tab-horizontal {
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  align-items: center; /* Better alignment */
}

p, .thq-body-small, a {
  font-size: 1rem; /* Adjusted font size for larger screens */
}

.project1-divider-container {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}

.project1-container2 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}

.project1-content {
  padding: 16px;  /* Adjust padding */
  gap: 16px;
  display: flex;
  flex-direction: column;
  overflow: auto; /* Ensures scrolling if content is too much */
  flex: 1;
  justify-content: flex-start;
}

* {
  box-sizing: border-box; /* Makes sure padding and borders are included in the total width/height */
  max-width: 100%; /* Prevents any element from exceeding the body width */
}

@media (max-width: 991px) {
  .project1-container1 {
    grid-template-columns: 1fr;
  }

  p, .thq-body-small, a {
    font-size: 1.2rem; /* Adjusted font size for medium screens */
  }
}

@media (max-width: 768px) {
  p, .thq-body-small, a {
    font-size: 1.1rem; /* Adjusted font size for small screens */
  }
}

.gallery-image {
  width: 100%;
  height: 0;
  padding-top: 75%; /* 4:3 aspect ratio */
  object-fit: cover;
}

@media (max-width: 991px) {
  .project1-tab-horizontal h2 {
      text-align: center;
      width: 100%; /* Ensure the title spans the full width */
  }

  .project1-content p, .project1-content span, .project1-content a {
    text-align: left; /* Consistent text alignment */
    word-break: break-word; /* Prevents text from overflowing */
  }

  .project1-tab-horizontal h2 {
    text-align: center; /* Center the heading on mobile */
  }
}

a {
  overflow-wrap: break-word; /* Breaks the URL if it's too long for one line */
}
