.contact-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #D9D9D9;
}
.contact-text {
  margin-top: var(--dl-space-space-twounits);
}
.contact-credits {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

html {
  overflow-x: initial !important;
}